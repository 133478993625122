import React, { useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, Button, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FailureNotification from 'ui-component/FailureNotification';
import SuccessNotification from 'ui-component/SuccessNotification';
import { useSelector } from 'react-redux';

const MultipleAppsUserOperations = ({title ,userId , userOperation, userKey, closeDialog}) => {
  const [appArray, setAppArray] = React.useState([]);
  const [approleArray, setAppRoleArray] = React.useState([]);


//********* || ALERTS  || ****************/

const [openError, setopenError] = React.useState(false);
const [ErrorMessage, setErrorMessage] = React.useState("");
const [openSuccess, setOpenSuccess] = React.useState(false);
const [SuccessMessage, setSuccessMessage] = React.useState("");
const [DropDownLoader, setDropDownLoader] = React.useState(true);
const [proceed, setProceed] = React.useState(false);
const [proceedApp, setProceedApp] = React.useState(false);
const [cardTitle, setCardTitle]= React.useState("")



  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_DISABLE_DEFAULT_APPS } =
    process.env;
  const DisableDefaultOktaApps = REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(',');
  const state = useSelector(state => state);
  const roles = state.authUser.roles;
  let roleID = roles.map((role) => role.split("partneriam-")[1]);
 let adminFind = roleID.includes("admin");


  //***************** || Alert Handle Close  || ************************* */ 

  const handleCloseAlertError = () => {
    setErrorMessage("");
    setopenError(false);
    
};

const handleCloseAlertSuccess = () => {
    setSuccessMessage("");
    setOpenSuccess(false);
};

  const getApps = async (searchValue) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}/users/get-assigned-application/${userId}`, {
        filterType: 2,
        value: 'ACTIVE',
      })
      .then((res) => {
        let appOptions = [];
        let approleOptions = []
        setDropDownLoader(!DropDownLoader)
        res.data.data?.map((item, index) => {
          if (!DisableDefaultOktaApps.includes(item.key) ) {
            if(roleID.includes(item.key)){
              approleOptions.push({
                label: item.value,
                id: item.key,
              });
            }
            appOptions.push({
              label: item.value,
              id: item.key,
            });
          }
          return item;
        });
        setAppArray(appOptions)
        setAppRoleArray(approleOptions)
        if(appOptions.length === 1){
          formik.setFieldValue("appID", appOptions[0].id)
        }
       setCardTitle(TitleSearch(title, appOptions))
      })
      .catch((err) => {
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  };

useEffect( () => {
     getApps('')
     //eslint-disable-next-line
},[])

//********** || DELETE USER ||  **************/



  let validationObject = {
    appID: yup.string().required('Application Name is required')
  };
  let initialValues = {
    appID: ''
  };
  const validationSchema = yup.object(validationObject);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      // deleteUser(userId, values.appID, userKey)
      userOperation(userId, values.appID, userKey)
    },
  });

  function TitleSearch(title, appList){
     if(appList.length > 1 && title === 'De-Activate User'){
      return 'Remove User'
     }else if( (appList.length === 1 && title === 'De-Activate User') || (appList.length > 1 && title === 'Delete User')){
      return 'De-activate User'
     }else if( (appList.length > 1 && title ==='Suspend User') || (appList.length === 1 && title === 'Suspend User') ){
       return 'Suspend User'
     }else if(appList.length === 1 && title === 'Un-Suspend User'){
       return 'Un-Suspend User'
     }
     else if( appList.length === 1 && title === 'Delete User'){
       return 'De-activate User'
     }
  }

  const closeActiveDialog = () => {
    closeDialog(userKey)
  }

  return (
    <>
    <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
    <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
    <MainCard title={cardTitle} id="Main">
      <Box
        component="form"
        sx={{
          '&.MuiTextField-root': { m: 0 },
          flexGrow: 1,
        }}
        onSubmit={formik.handleSubmit}
      >
        
        {
        appArray && appArray.length > 1 && (title === "Suspend User" || title === "Un-Suspend User") ?
        <Grid container spacing={4} item xs={12}>
          <Grid item xs={12}>
          <Typography sx={{ fontSize: '20px', color: 'black', fontWeight: 'bold', display: 'inline-block' }}>
            <span style={{ color: 'red' }}>Note:</span> You cannot {cardTitle} because the user is associated with multiple applications, However you can de-activate the user by clicking on deactivate option under actions section
          </Typography>
          </Grid>
        </Grid>
        :
        <>
        {
          appArray && appArray.length === 1 && !proceed &&
        
          <Card sx={{ maxWidth: 400, margin: '0 auto', textAlign: 'center' }}>
      <CardContent>
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 2 }}>
         User is part of Single Application, Click on Proceed to  {cardTitle}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={closeActiveDialog}>Close</Button>
        <Button variant="contained" color="primary"  onClick={() => setProceed(!proceed)}>Proceed</Button>
      </CardActions>
      {
        cardTitle === 'De-activate User' &&
        <Typography sx={{fontSize:'13px', color:'GrayText', fontWeight:'bolder', padding: '10px'}}>
          <span style={{color:'black'}}>Note:</span>if you want to undo above action , please reach out to the Okta team  
        </Typography>
      }
     
      </Card>
        }
        <Grid container spacing={4} item xs={12}>
        {
          appArray && appArray.length > 1 &&
          <>
          {
            !proceedApp &&
            <Card sx={{ maxWidth: 400, margin: '0 auto', textAlign: 'center' }}>
      <CardContent>
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 2 }}>
       { appArray.length > 1 ? `User is part of Multiple Applications,However you can  Remove User from selected Application by clicking on proceed` : `User is part of Your Application, Click on Proceed to Delete the User. If you want to add this user please contact Okta Team` }  
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined"  onClick={closeActiveDialog}>Close</Button>
        <Button variant="contained"  onClick={() => setProceedApp(!proceed)}>Proceed</Button>
      </CardActions>
      <Typography sx={{fontSize:'13px', color:'GrayText', fontWeight:'bolder', marginTop:'10px'}}>
               <span style={{color:'black'}}>Note:</span> if you want to undo above action , please contact okta team Next time
        </Typography>
      </Card>
          }
          
          {
            proceedApp &&
          <>
          <Grid item xs={6}>
          <Autocomplete
              options={adminFind ? appArray : approleArray}
              name='appID'   
              onChange={ (e , value) => {
                  formik.setFieldValue("appID", value?.id)
              }}
              loading={DropDownLoader}
              loadingText="Loading..."
              onBlur={() => {
                formik.setFieldTouched('appID')
              }}
              renderInput={ (params) => (
                <TextField 
                  {...params}
                    label="Application Name*"
                    onChange={ (e) => {
                      getApps(e.target.value)
                    }}
                    error={formik.touched.appID && Boolean(formik.errors.appID)}
                    helperText={formik.touched.appID && formik.errors.appID}
                />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
           />
          </Grid>
          <Grid item  xs={6} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>   
          <Grid item xs={12}>
              <Button
              variant="outlined"
              type="submit"
              color='error'
              disabled={!formik.values.appID}
              >
              {' '}
              {cardTitle}
              </Button>
          </Grid>
        </Grid> 
          </>
            }
          </>
        }


          {
            proceed  &&
          <Grid item  xs={6} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>   
            <Grid item xs={12}>
                <Button
                variant="outlined"
                type="submit"
                color='error'
                disabled={!formik.values.appID}
                >
                {' '}
                {cardTitle}
                </Button>
            </Grid>
          </Grid> 
          }
        </Grid>
        </>
        }
      </Box>
    </MainCard>
    </>
  );
};

export default MultipleAppsUserOperations;
