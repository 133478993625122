import { OktaAuth } from '@okta/okta-auth-js'
// import { USER_LOGIN } from 'actions/AuthActions';
import Axios from 'axios';

const {REACT_APP_OKTA_REDIRECTURI,REACT_APP_OKTA_POST_LOGOUT_REDIRECTURI, REACT_APP_OKTA_ISSUER, REACT_APP_OKTA_CLIENTID} = process.env
const interceptor = (store) => {
  // const CurrentState = store.getState()
  // const CurrentAuthState =  CurrentState.authUser.authState
  var authClient = new OktaAuth({
    issuer: REACT_APP_OKTA_ISSUER,
    clientId: REACT_APP_OKTA_CLIENTID,
    redirectUri: REACT_APP_OKTA_REDIRECTURI,
    postLogoutRedirectUri:REACT_APP_OKTA_POST_LOGOUT_REDIRECTURI,
    pkce: true,
    scopes: [
      "profile",
      "email",
      "openid",
      "offline_access"
    ]
  })

  Axios.interceptors.request.use(
    (conf) => {
      // const authUser = store.getState().authUser;   
      const AccessToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken
        if(conf.url.endsWith('/health') || conf.url.split('?')[0].endsWith('/activate-user')){
            return conf;
        }else{
            conf.headers.Authorization = "Bearer "+ AccessToken;
            return conf;
        }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
 
  Axios.interceptors.response.use((res) => {
    return res;
  },
   async (err) => {
    
    const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {          
            await authClient.tokenManager.renew('accessToken')
            .then(function(newToken){
              // console.log({newToken})
            //   store.dispatch({ type:USER_LOGIN,payload:{
            //     authState:{
            //     ...CurrentAuthState,
            //     accessToken : newToken
            //     },
            //   isAuthenticated:CurrentState.authUser.isAuthenticated,
            //   roles:CurrentState.authUser.roles}
            //  })
            })
          
            return Axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }}
      return Promise.reject(err);
    }
  );
};



//eslint-disable-next-line
export default {interceptor}